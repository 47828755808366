// Generated by Framer (4431e6b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const enabledGestures = {XRZU4nNE2: {hover: true}};

const cycleOrder = ["XRZU4nNE2"];

const variantClassNames = {XRZU4nNE2: "framer-v-7u7la1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; link?: string; platform?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "XRZU4nNE2", link: NbTPvaL_U, platform: rg2yMd1I2 = "FacebookLogo", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "XRZU4nNE2", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-PhG4v", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={NbTPvaL_U} openInNewTab><motion.a {...restProps} className={`${cx("framer-7u7la1", className)} framer-6qq1zc`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"XRZU4nNE2"} ref={ref} style={{...style}} {...addPropertyOverrides({"XRZU4nNE2-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-8pnymu-container"} layoutDependency={layoutDependency} layoutId={"UuXokmAeg-container"}><Phosphor color={"var(--token-5e489686-0615-4d18-a779-26b95763b9ac, rgb(212, 211, 208)) /* {\"name\":\"Base/ Light Grey\"} */"} height={"100%"} iconSearch={"House"} iconSelection={rg2yMd1I2} id={"UuXokmAeg"} layoutId={"UuXokmAeg"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"} {...addPropertyOverrides({"XRZU4nNE2-hover": {color: "var(--token-858b61a4-e7d9-4305-b52b-3a761758111c, rgb(155, 155, 18)) /* {\"name\":\"Primary/ 400\"} */"}}, baseVariant, gestureVariant)}/></motion.div></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-PhG4v [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PhG4v .framer-6qq1zc { display: block; }", ".framer-PhG4v .framer-7u7la1 { height: 24px; overflow: visible; position: relative; text-decoration: none; width: 24px; }", ".framer-PhG4v .framer-8pnymu-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }", ".framer-PhG4v .framer-v-7u7la1 .framer-7u7la1 { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"GNFQbnsW8":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"NbTPvaL_U":"link","rg2yMd1I2":"platform"}
 */
const Framerto2exdSOm: React.ComponentType<Props> = withCSS(Component, css, "framer-PhG4v") as typeof Component;
export default Framerto2exdSOm;

Framerto2exdSOm.displayName = "Elemental/ Social Media Icon";

Framerto2exdSOm.defaultProps = {height: 24, width: 24};

addPropertyControls(Framerto2exdSOm, {NbTPvaL_U: {title: "Link", type: ControlType.Link}, rg2yMd1I2: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "FacebookLogo", hidden: undefined, title: "Platform"}} as any)

addFonts(Framerto2exdSOm, [...PhosphorFonts])